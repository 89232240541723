import React from "react";
import styles from "@buddieshr/styles/components/pricingButtonHint.module.scss";
import { useTranslation } from "react-i18next";

const PricingButtonHint = ({ style = {} }) => {
  const { t } = useTranslation();
  return (
    <div
      className={styles.Pricing_button_hint}
      style={{
        justifyContent: "space-around",
        ...style,
      }}
    >
      <div>{`💳 ${t("noCreditCard")}`}</div>
      &nbsp;
      &nbsp;
      <div>{`🤝 ${t("freeTrial")}`}</div>
    </div>
  );
};

export default PricingButtonHint;
