/* eslint-disable react/no-unescaped-entities */
import React from "react";
import styles from "@buddieshr/styles/components/getStarted.module.scss";
import TextColored from "../text/textColored";
import { Divider } from "antd";

const GetStarted = ({ app }) => (
  <div id="getstarted">
    <div className={styles.howItWorks}>
      <h2 className={styles.howItWorksTitle}>
        How to <TextColored app={app}>get started</TextColored>
      </h2>
      <div className={styles.howItWorksSubtitle}>
        {`${app.getStartedSteps.length} simple steps to install and launch ${app.name}`}
      </div>
    </div>

    <div className={styles.gettingStartedWrapper}>
      {app.getStartedSteps.map((step, index) => (
        <div key={step.title}>
          <div className={styles.gettingStartedElement}>
            <h3 className={styles.gettingStartedTitle}>
              <span className={styles.icon}>{step.icon}</span>
              {`${index + 1}. ${step.title}`}
            </h3>
            <div className={styles.gettingStartedDesc}>{step.description}</div>
          </div>
          {index < app.getStartedSteps.length - 1 && <Divider />}
        </div>
      ))}
    </div>
  </div>
);

export default GetStarted;
