import styles from "@buddieshr/styles/components/textcolor.module.scss";

const TextColored = ({ children, app }) => (
  <span
    style={app ? { backgroundImage: app.textColorBackground } : {}}
    className={styles.text}
  >
    {children}
  </span>
);

export default TextColored;
