import styles from "@buddieshr/styles/components/installnow.module.scss";
import TextColored from "./text/textColored";
import AddToSlackButton from "./addToSlackButton";
import Image from "next/image";

const InstallNow = ({
  app,
  title = "Ready to gather unique insights from your team?",
  subtitle = "Get started now and measure your eNPS today.",
  gradient = "linear-gradient(273deg, #c5e4fa, #badffa)",
}) => {
  const { name, logoUrl } = app;
  return (
    <div className={styles.wrapper}>
      <div
        className={styles.inner}
        style={{
          background: gradient,
        }}
      >
        <div className={styles.icon}>
          <Image
            alt={`logo ${name}`}
            src={logoUrl}
            width={130}
            height={130}
            className={styles.logo}
          />
        </div>
        <div className={styles.title}>
          <h2>
            <TextColored app={app}>{title}</TextColored>
          </h2>
        </div>
        <div className={styles.subtitle}>{subtitle}</div>
        <div className={styles.actions}>
          <AddToSlackButton app={app} />
          <div className={styles.hint}>Free - No card required</div>
        </div>
      </div>
    </div>
  );
};

export default InstallNow;
